<template>
    <div class="dashboard-published-discounts">
        <DashCardRouter :title="$lang.pages.dashboardDescuentos.discounts_published" />
        <ul v-if="discounts.length" class="dashboard-published-discounts__list">
            <li v-for="(discount, index) in discounts" :key="index" class="list-summary__item">
                <DCardMain :info="discount" />
            </li>
        </ul>

        <div v-else>
            <MiscNoResults mode="discounts" :title="$lang.pages.dashboardDescuentos.no_discounts_published" />
            <div class="no-activity">
                <NuxtLink no-prefetch class="no-activity__btn" :to="`/${$lang.routes.share}`">{{
                    $lang.pages.dashboardDescuentos.publish_one
                }}</NuxtLink>
            </div>
        </div>
        <ButtonLoadMore v-if="fetchButton.show" :loading="fetchButton.loading" :handler="getMoreDiscounts" />
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

const { UserData, SessionToken } = useAuthStore()
const { $lang } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const params = reactive({
    per_page: 10,
    page: 1,
    user_id: UserData?.id,
})
const getDiscounts = () =>
    $fetch<Api.Responses.General.IndividualModel<Models.Discount>>(endpoints.general.discounts.path, {
        headers: buildHeaders(SessionToken),
        baseURL,
        method: 'GET',
        params,
    })

const { data: responseData, error } = await useAsyncData('dashboard-user-activities', () =>
    getDiscounts().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: 404,
        message: responseData.value?.feedback || error.value?.message || 'Error data',
    })
}

const discounts = ref(responseData.value.data.results)
const fetchButton = reactive({
    loading: false,
    show:
        responseData.value.data.meta.total > 0 &&
        responseData.value.data.meta.current_page < responseData.value.data.meta.last_page,
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.dashboardDescuentos.title_seo
const description = $lang.pages.dashboardDescuentos.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const getMoreDiscounts = async () => {
    fetchButton.loading = true

    const { feedback, data } = await getDiscounts()

    if (feedback === 'data_success') {
        discounts.value = [...discounts.value, ...data.results]

        if (data.meta.current_page < data.meta.last_page) {
            params.page = data.meta.current_page + 1
        } else {
            fetchButton.show = false
        }
    }

    fetchButton.loading = false
}
</script>

<style lang="postcss" scoped>
.dashboard-published-discounts {
    @apply space-y-3 bg-white p-2 xs:rounded-xl;
    &__list {
        @apply space-y-3;
    }
    .no-activity {
        @apply mb-5 mt-5 flex w-full flex-wrap items-center justify-center gap-2;
        &__btn {
            @apply rounded-xl bg-site-primary px-5 py-3 text-white transition duration-200 lg:hover:brightness-110;
        }
    }
}
</style>
